<template>
  <div class="view-login auth mb-5">
    <div class="container">
      <div class="row pt-lg-4">
        <div class="col-md-6 col-lg-5 mx-auto">
          <form @submit.prevent="submit">
            <div class="card-body pt-0 pb-5 py-lg-4 px-0 px-lg-5">
              <div class="text-center">
                <img
                  width="181"
                  height="97.27"
                  src="@/assets/images/logo.png"
                />
              </div>
              <h2 class="text-center my-2 my-lg-4 text-uppercase">
                Faça seu login
              </h2>

              <form-group
                v-model="email"
                :isLogin="true"
                id="email"
                type="email"
                label="Email"
                placeholder="Digite seu e-mail"
                :errors="errors.email"
                :show-error-message="false"
                @change="resetErrors"
              />

              <form-group
                v-model="password"
                :isLogin="true"
                id="password"
                type="password"
                label="Senha"
                placeholder="Digite a sua senha"
                :errors="errors.password"
                :show-error-message="false"
                @change="resetErrors"
              />
              <router-link
                class="btn btn-sm mt-3 p-0 font-weight-normal"
                :to="{ name: 'forgot-password' }"
              >
                <small>Esqueceu sua senha?</small>
              </router-link>
              <div class="text-center py-4">
                <button
                  type="submit"
                  :disabled="!isValidFields"
                  class="btn btn-primary btn-block"
                >
                  <loading :show="showLoading"> Entrar </loading>
                </button>
              </div>
              <p class="text-center mb-0">
                <router-link class="btn btn-sm" :to="{ name: 'signup' }">
                  <span class="font-weight-normal mr-2">
                    Não tem uma conta?
                  </span>
                  <span class="text-primary font-weight-bold">
                    Criar uma conta.
                  </span>
                </router-link>
              </p>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { authMixin } from "@/mixins";
import get from "lodash/get";

export default {
  name: "login",
  mixins: [authMixin],
  data() {
    return {
      email: "",
      password: "",
      errors: {
        email: [],
        password: [],
        non_field_errors: []
      },
      showLoading: false,
      forgotDialog: false,
      isValidFields: false
    };
  },
  computed: {
    errorMsg() {
      return (
        get(this.errors, "email[0]") ||
        get(this.errors, "password[0]") ||
        get(this.errors, "non_field_errors[0]")
      );
    }
  },
  watch: {
    password() {
      this.validateFields();
    },
    email() {
      this.validateFields();
    }
  },
  methods: {
    validateFields() {
      const isValidEmail = !!this.email && /\S+@\S+\.\S+/.test(this.email);
      const isValidPassword = !!this.password && this.password.length > 0;
      this.isValidFields = isValidEmail && isValidPassword;
    },
    resetErrors() {
      this.errors = {
        email: [],
        password: [],
        non_field_errors: []
      };
    },
    goToForgotPassword() {
      this.$router.push("/password-recovery");
    },
    submit() {
      this.showLoading = true;

      this.$store
        .dispatch("user/login", {
          email: this.email,
          password: this.password
        })
        .then(() => {
          let url = localStorage.getItem("urlpath");
          if (url) {
            this.$router.replace({ path: `${url}`, query: { message: true } });
            localStorage.removeItem("urlpath");
            localStorage.removeItem("url");
          } else {
            this.$router.replace({ name: "home", query: { message: true } });
          }
        })
        .catch(this.handleErrors)
        .finally(this.handleFinally);
    }
  }
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/_variables.scss";

.view-login {
  width: 100%;

  .btn-sm {
    font-size: 14px;
    font-weight: 900;
  }
}
</style>
